// Imports => Dependencies
import React, { createContext } from 'react';
import { makeObservable, observable, action } from 'mobx';

// Imports => API
import createApi from '@api';

// Imports => Stores
import FlowStore from '@stores/flow.store';
import TenantStore from '@stores/tenant.store';
import ToastersStore from '@stores/toasters.store';
import UiStore from '@stores/ui.store';

class Store {
	constructor(config) {
		makeObservable(this);

		this.config = config;

		this.api = createApi(config);

		this.flow = new FlowStore(this);
		this.tenant = new TenantStore(this);
		this.toasters = new ToastersStore(this);
		this.ui = new UiStore(this);
	}

	@action
	resetStores = () => {
		return new Promise((resolve) => {
			resolve();
		});
	};

	@observable swUpdated = false;
}

export default Store;
