export const TITLES = {
	BASE: 'Allshoes Shoe Selector',
	ACCOUNT: 'Account',
	DASHBOARD: 'Dashboard',
	DELETE_CONFIRM: 'Delete',
	DETAILS: 'Details',
	FORGOT_PASSWORD: 'Forgot your password?',
	KEYBOARD_SHORTCUTS: 'Keyboard shortcuts',
	LOGIN: 'Login',
	NO_INTERNET_CONNECTION: 'You appear to be offline',
	OVERVIEW: 'Overview',
	PROFILE: 'Your profile & settings',
	RESET_PASSWORD: 'Change your password',
	SESSION_TIMEOUT: 'Your session is about to expire',
	SESSION_EXPIRED: 'Your session has expired',
	SETTINGS: 'Settings',
	SUPPORT: 'Help & Support',
	WELCOME: 'Welcome',
	YOUR_PROFILE_AND_SETTINGS: 'Your profile and settings',
};
