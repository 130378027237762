// Imports => Utilities
import { AcIsSet } from './ac-get-type-of'

export const AcApplyTheme = (styles = {}, applyThemeElement) => {
  if (!AcIsSet(styles)) return
  if (!AcIsSet(applyThemeElement)) return

  const keys = Object.keys(styles)
  const len = keys.length
  let n = 0

  for (n; n < len; n++) {
    const property = keys[n]
    const value = styles[property]

    if (!AcIsSet(value)) continue
    applyThemeElement.style.setProperty(property, value)
  }
}
