import 'preact/debug'
import { createInit } from './boot.app'
import '@styles/index.scss'

const qString = window.location.search.substring(1)
const searchParams = new URLSearchParams(qString)
const tenant = searchParams.get('tenant')
const squeezelyId = searchParams.get('squeezelyId')

createInit()({ tenant, squeezelyId })
