// Imports => Vendor
import axios from 'axios'

// Imports => MOBX
import { makeAutoObservable, observable, computed, action } from 'mobx'

// Imports => Constants
import { KEYS } from '@constants'

// Imports => Utilities
import {
  AcSanitize,
  AcAutoLoad,
  AcAutoSave,
  AcSaveState,
  AcGetHash,
  AcGetState,
  AcRemoveState,
  AcRemoveHash,
  AcIsSet,
  AcIsNull,
  AcFormatErrorMessage,
  AcFormatErrorCode,
  AcIsUndefined,
} from '@utils'

const _default = {
  tenant: null,
  tenant_slug: null,
}

let app = {}

export class TenantStore {
  constructor(store) {
    makeAutoObservable(this)

    AcAutoLoad(this, KEYS.TENANT)
    AcAutoLoad(this, KEYS.TENANT_SLUG)
    AcAutoSave(this)

    app.store = store
  }

  @observable
  tenant = _default.tenant

  @observable
  tenant_slug = _default.tenant_slug

  @computed
  get current_tenant() {
    return this.tenant
  }

  @computed
  get current_tenant_slug() {
    return this.tenant_slug
  }

  @observable
  loading = {
    status: false,
    message: null,
  }

  @computed
  get is_loading() {
    return this.loading.status
  }

  @action
  setLoading = (state, message) => {
    this.loading = {
      status: state || false,
      message,
    }
  }

  @observable
  busy = {
    status: false,
    message: null,
  }

  @computed
  get is_busy() {
    return this.busy.status
  }

  @action
  setBusy = (state, message) => {
    this.busy = {
      status: state || false,
      message,
    }
  }

  @action
  get_tenant_slug = (data) => {
    this.setLoading(true)

    this.set(KEYS.TENANT_SLUG, data)
    this.setLoading(false)
  }

  @action
  initiate = async () => {
    this.setLoading(true)

    if (app.store.tenant.tenant_slug) {
      return app.store.api.tenant
        .initiate_with_slug(await app.store.tenant.tenant_slug)
        .then(async (response) => {
          if (response.status === 200) {
            const {
              data: { data },
            } = response
            await this.set(KEYS.TENANT, data)
          }

          this.setLoading(false)

          return response?.data?.data
        })
        .catch((error) => {
          if (!axios.isCancel(error))
            app.store.toasters.add({
              variant: 'error',
              title: 'Geen geldige merk styling gevonden',
              description: AcFormatErrorMessage(error),
              code: AcFormatErrorCode(error),
              closeable: true,
            })
          this.setLoading(false)
          if (!axios.isCancel(error)) throw error
        })
    } else {
      return app.store.api.tenant.initiate_with_origin().then(async (response) => {
        if (response.status === 200) {
          const {
            data: { data },
          } = response

          await this.set(KEYS.TENANT, data)
        }
        this.setLoading(false)

        return response?.data?.data
      })
    }
  }

  @action
  set = (target, value, save = true) => {
    if (!AcIsSet(target)) return
    if (AcIsUndefined(this[target])) return
    if (AcIsUndefined(value)) return

    return new Promise((resolve) => {
      this[target] = value
      if (save) AcSaveState(target, value)
      resolve()
    })
  }

  @action
  reset = (target, save = true) => {
    if (!AcIsSet(target)) return
    if (AcIsUndefined(this[target])) return

    return new Promise((resolve) => {
      this[target] = _default[target]
      if (save && AcIsNull(_default[target])) {
        AcRemoveState(target)
      } else if (save) {
        AcSaveState(target, _default[target])
      }

      resolve()
    })
  }
}

export default TenantStore
