import 'preact/debug'
import React from 'react'
import { render } from 'preact'

import { unregister } from './registerServiceWorker'

import { createBrowserHistory } from 'history'
import { RouterStore, syncHistoryWithStore } from 'mobx-react-router'
import { BrowserRouter as Router } from 'react-router-dom'

import '@config/i18next'
import config from '@config'
import createStore, { StoreContext } from '@stores'
import { KEYS } from '@constants'

import App from '@src/App'
import Favicon from '@assets/images/favicon.ico'

const initServiceWorker = () => {
  unregister()
}

const initiateFlow = async (squeezely_id) => {
  const params = new URLSearchParams(window.location.search)
  const squeezelyParamsId = params.get(KEYS.SQUEEZLY_QUERY_PARAM)
  await store.flow.initiateFlow(squeezely_id || squeezelyParamsId)
}

const browserHistory = createBrowserHistory()
const routing = new RouterStore()
const store = createStore(config)
const history = syncHistoryWithStore(browserHistory, routing)

export const createInit = (runAsWidget = false) => {
  return async ({ tenant, squeezelyId, selector = 'root', themeSelector = '' } = {}) => {
    if (!runAsWidget) {
      initServiceWorker()
    }

    await initiateFlow(squeezelyId)

    window.tenant = tenant

    const container = typeof selector === 'string' ? document.getElementById(selector) : selector

    render(
      <StoreContext.Provider value={store}>
        <Router history={history}>
          <App
            tenant={tenant}
            applyThemeElement={
              themeSelector
                ? typeof themeSelector === 'string'
                  ? document.getElementById(themeSelector)
                  : themeSelector
                : document.documentElement
            }
          />
        </Router>
      </StoreContext.Provider>,
      container,
    )
  }
}
