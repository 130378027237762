// Imports => Vendor
import axios from 'axios'

// Imports => MOBX
import { makeObservable, observable, computed, action } from 'mobx'

// Imports => Constants
import { KEYS, ROUTES } from '@constants'

// Imports => Utilities
import {
  AcSanitize,
  AcAutoLoad,
  AcAutoSave,
  AcSaveState,
  AcGetHash,
  AcGetState,
  AcRemoveState,
  AcRemoveHash,
  AcIsSet,
  AcIsNull,
  AcFormatErrorMessage,
  AcFormatErrorCode,
  AcIsUndefined,
} from '@utils'

// Imports => Dependencies
import { CopyToClipboard } from 'react-copy-to-clipboard'

const _default = {
  flow: null,
  question: null,
  flowId: null,
  state: {},
  squeezelyId: null,
  results: null,
  bit: null,
  shareHash: null,
  answeredQuestions: null,
  firstQuestion: null,
}

let app = {}

export class FlowStore {
  constructor(store) {
    makeObservable(this)

    AcAutoLoad(this, KEYS.FLOW)
    AcAutoLoad(this, KEYS.FLOW_ID)
    AcAutoLoad(this, KEYS.QUESTION)
    AcAutoLoad(this, KEYS.STATE)
    AcAutoLoad(this, KEYS.SQUEEZLY_ID)
    AcAutoLoad(this, KEYS.RESULTS)
    AcAutoLoad(this, KEYS.BIT)
    AcAutoLoad(this, KEYS.SHARE_HASH)
    AcAutoLoad(this, KEYS.ANSWERED_QUESTIONS)
    AcAutoSave(this)

    app.store = store
  }

  @observable
  flowId = _default.flowId

  @observable
  squeezelyId = _default.squeezelyId

  @observable
  flow = _default.flow

  @observable
  question = _default.question

  @observable
  state = _default.state

  @observable
  bit = _default.bit

  @observable
  results = _default.results

  @observable
  shareHash = _default.shareHash

  @observable
  answeredQuestions = _default.answeredQuestions

  @observable
  firstQuestion = _default.firstQuestion

  @computed
  get current_answeredQuestions() {
    return this.answeredQuestions
  }

  @computed
  get current_shareHash() {
    return this.shareHash
  }

  @computed
  get current_flow() {
    return this.flow
  }

  @computed
  get current_question() {
    return this.question || this.flow
  }

  @computed
  get current_state() {
    return this.state
  }

  @computed
  get current_bit() {
    return this.bit
  }

  @computed
  get current_results() {
    const stored = AcGetState(KEYS.RESULTS)
    if (AcIsSet(stored)) return stored

    return this.results
  }

  @observable
  loading = {
    status: false,
    message: null,
  }

  @computed
  get is_loading() {
    return this.loading.status
  }

  @action
  setLoading = (state, message) => {
    this.loading = {
      status: state || false,
      message,
    }
  }

  @observable
  busy = {
    status: false,
    message: null,
  }

  @computed
  get is_busy() {
    return this.busy.status
  }

  @action
  setBusy = (state, message) => {
    this.busy = {
      status: state || false,
      message,
    }
  }

  @action
  initiateFlow = (trackingId) => {
    this.setLoading(true)
    return app.store.api.flow
      .initiateFlow(trackingId)
      .then(async (res) => {
        await this.set(KEYS.FLOW_ID, res?.data?.uuid)
        await this.set(KEYS.SQUEEZLY_ID, res?.data?.squeezely_id)
        this.setLoading(false)
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          app.store.toasters.add({
            variant: 'error',
            title: 'Failed to get a flow-id',
            description: AcFormatErrorMessage(error),
            code: AcFormatErrorCode(error),
          })
        }
        this.setLoading(false)
        if (!axios.isCancel(error)) throw error
      })
  }

  @action
  sendEmailAndConsent = (emailAddress, hasConsent, addResults = true) => {
    this.setLoading(true)
    const collection = this.current_state
    let opt
    let data = []
    if (addResults)
      for (opt in collection) {
        data.push({ question_id: opt, option_uuid: collection[opt] })
      }

    return app.store.api.flow
      .mailResults(emailAddress, this.flowId, hasConsent, data)
      .then(async (res) => {
        this.setLoading(false)
        return res
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          app.store.toasters.add({
            variant: 'error',
            title: 'Fout bij versturen van het email-adres',
            description: AcFormatErrorMessage(error),
            code: AcFormatErrorCode(error),
          })
        }

        this.setLoading(false)

        if (!axios.isCancel(error)) throw error
      })
  }

  @action
  initiate = () => {
    this.setLoading(true)

    return app.store.api.flow
      .initiate(this.flowId)
      .then(async (response) => {
        await this.set(KEYS.QUESTION, response)

        // if (!this.firstQuestionId) {
        //   this.firstQuestionId = response.id
        //   await this.set(KEYS.FIRST_QUESTION, response.id)
        // }

        const { id, options } = response

        await this.set(KEYS.BIT, { ...this.bit, [id]: 0 })

        const collection = options
        const len = collection.length
        let n = 0
        let result = {}

        for (n; n < len; n++) {
          if (collection[n] && collection[n].is_default) {
            result = { ...result, [id]: collection[n].uuid }
          }
        }

        await this.set(KEYS.STATE, result)

        this.setLoading(false)

        return response
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          app.store.toasters.add({
            variant: 'error',
            title: 'Failed to initiate flow',
            description: AcFormatErrorMessage(error),
            code: AcFormatErrorCode(error),
          })
        }

        this.setLoading(false)

        if (!axios.isCancel(error)) throw error
      })
  }

  @action
  share_results = async () => {
    const state = this.current_state
    return app.store.api.flow.share_results(state).then(async (response) => {
      if (response.status === 200) {
        await this.set(KEYS.SHARE_HASH, response.data.data.uuid)
      }
      return response
    })
  }

  @action
  share_click = async () => {
    app.store.toasters.add({
      variant: 'success',
      title: 'Link succesvol gekopieerd',
    })
  }

  @action
  shared_flow = async () => {
    const hash = AcGetHash()
    if (!hash) return
    this.setLoading(true)

    return app.store.api.flow
      .shared_flow(hash)
      .then(async (response) => {
        if (response.status === 200) {
          await this.set(KEYS.RESULTS, response.data)
          AcRemoveHash()
          this.setLoading(false)
        } else !axios.isCancel()
        return response
      })
      .catch((error) => {
        AcRemoveHash()
        if (!axios.isCancel(error))
          app.store.toasters.add({
            variant: 'error',
            title: 'Geen geldige deel link gevonden',
            description: AcFormatErrorMessage(error),
            code: AcFormatErrorCode(error),
            closeable: true,
          })
        this.setLoading(false)
        if (!axios.isCancel(error)) throw error
      })
  }

  @action
  next_question = () => {
    this.setLoading(true)

    const collection = this.current_state
    let opt
    let data = []

    if (!this.firstQuestion) {
      this.firstQuestion = { ...this.current_state }
    }

    for (opt in collection) {
      data.push({ question_id: opt, option_uuid: collection[opt] })
    }

    return app.store.api.flow
      .next_question(data, this.flowId)
      .then(async (response) => {
        const { type, data } = response
        if (type === KEYS.PRODUCTS) {
          await this.set(KEYS.RESULTS, response)
        } else {
          await this.set(KEYS.QUESTION, response.data)

          const { id, options } = data

          const collection = options
          const len = collection.length
          let n = 0
          let result = Object.assign({}, this.current_state)
          let default_option = collection && collection[0] && collection[0].uuid

          for (n; n < len; n++) {
            if (collection[n] && collection[n].is_default) {
              default_option = collection[n].uuid
            }
          }

          if (AcIsSet(default_option)) {
            result = { ...result, [id]: default_option }
          }
          await this.set(KEYS.STATE, result)
        }

        this.setLoading(false)

        return response
      })
      .catch((error) => {
        this.setLoading(false)

        if (!axios.isCancel(error))
          app.store.toasters.add({
            variant: 'error',
            title: 'Failed to get the next question',
            description: AcFormatErrorMessage(error),
          })

        if (!axios.isCancel(error)) throw error
      })
  }

  @action
  previous_question = () => {
    if (!this.answeredQuestions) return
    this.setLoading(true)

    const current = this.current_question
    let collection = this.current_state
    if (collection[current.id]) delete collection[current.id]
    let opt
    let data = []

    for (opt in collection) {
      if (opt === current.id) continue
      data.push({ question_id: opt, option_uuid: collection[opt] })
    }

    // 1st sector question has larger id then next questions!
    if (this.firstQuestion) {
      const key = Object.keys(this.firstQuestion)[0]
      data = [{ question_id: key, option_uuid: this.firstQuestion[key] }, ...data]
      data.splice(-1, 1)
    }
    const splicedData = data.splice(-1, 1)

    for (let index = 0; index < data.length; index++) {
      if (data[index] === splicedData) {
        data.splice(index, 1)
      }
    }
    const questions = this.answeredQuestions

    if (questions.length > 0) {
      questions.splice(-1, 1)
      this.set(KEYS.ANSWERED_QUESTIONS, questions)
    }

    const bitKeys = Object.keys(this.bit)

    if (bitKeys && bitKeys.length > 0) {
      delete this.bit[this.question.id]
    }

    return app.store.api.flow
      .previous_question(data, this.flowId)
      .then(async (response) => {
        await this.set(KEYS.QUESTION, response)

        const { id, options } = response

        const collection = options
        const len = collection.length
        let n = 0
        let result = Object.assign({}, this.current_state)
        if (result[current.id]) delete result[current.id]

        // for (n; n < len; n++) {
        // 	if (collection[n] && collection[n].is_default) {
        // 		result = { ...result, [id]: collection[n].uuid };
        // 	}
        // }

        await this.set(KEYS.STATE, result)

        this.setLoading(false)

        return response
      })
      .catch((error) => {
        this.setLoading(false)

        if (!axios.isCancel(error))
          app.store.toasters.add({
            variant: 'error',
            title: 'Failed to get the previous question',
            description: AcFormatErrorMessage(error),
          })

        if (!axios.isCancel(error)) throw error
      })
  }

  @action
  set = (target, value, save = true) => {
    if (!AcIsSet(target)) return
    if (AcIsUndefined(this[target])) return
    if (AcIsUndefined(value)) return

    return new Promise((resolve) => {
      this[target] = value
      if (save) AcSaveState(target, value)
      resolve()
    })
  }

  @action
  setState = (target, property, value, save) => {
    if (!AcIsSet(target)) return
    if (AcIsUndefined(this[target])) return
    if (!AcIsSet(property)) return
    if (AcIsUndefined(value)) return

    this[target][property] = value
    if (save) AcSaveState(target, value)
  }

  @action
  reset = (target, save = true) => {
    if (!AcIsSet(target)) return
    if (AcIsUndefined(this[target])) return

    return new Promise((resolve) => {
      this[target] = _default[target]
      if (save && AcIsNull(_default[target])) {
        AcRemoveState(target)
      } else if (save) {
        AcSaveState(target, _default[target])
      }

      resolve()
    })
  }
}

export default FlowStore
