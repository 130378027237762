export const POSITIONS = {
	TOP: 'top',
	RIGHT: 'right',
	BOTTOM: 'bottom',
	LEFT: 'left',
};

export const SIZES = {
	SMALL: 'small',
	DEFAULT: 'default',
	MEDIUM: 'medium',
	LARGE: 'large',
};

export const THEMES = {
	ALPHA: 'alpha',
	ALPHA_LIGHT: 'alpha_light',
	OMEGA: 'omega',
	OMEGA_ALT: 'omega-alt',
	BETA: 'beta',
	DEFAULT: 'default',
	TRANSPARENT: 'transparent',
	WHITE: 'white',
	OFFWHITE: 'offwhite',
	LIGHT: 'light',
	SUBTLE: 'subtle',
	MEDIUM: 'medium',
	DARK: 'dark',
	PITCH: 'pitch',
	BLACK: 'black',
	PLACEHOLDER: 'placeholder',
	PATTERN: 'pattern',
	FACEBOOK: 'facebook',
	INSTAGRAM: 'instagram',
	YOUTUBE: 'youtube',
	TWITTER: 'twitter',
};

export const TYPES = {
	TEXT: 'text',
	TEXTAREA: 'textarea',
	PASSWORD: 'password',
	NUMBER: 'number',
	EMAIL: 'email',
	STRING: 'string',
	BOOLEAN: 'boolean',
	DATE: 'date',
	RANGE: 'range',
	ALPHANUMERIC: 'alphanumeric',
	NUMERIC: 'numeric',
	ZIPCODE: 'zipcode',
	CHECKBOX: 'checkbox',
	TOGGLE: 'toggle',
	RADIO: 'radio',
	SUBMIT: 'submit',
	BUTTON: 'button',
	BACKGROUND: 'background',
	IMAGE: 'image',
	WEBSITE: 'website',
	HIDDEN: 'hidden',
	DELETE: 'delete',
	LINK: 'link',
	SELECT: 'select',
	ON_OFF: 'on_off',
	DOUBLE: 'double',
};

export const VARIANTS = {
	OUTLINE: 'outline',
	DEFAULT: 'default',
	SUBTLE: 'subtle',
	TEXT: 'text',
	TRANSPARENT: 'transparent',
	UPPERCASE: 'uppercase',
	LOWERCASE: 'lowercase',
	SUCCESS: 'success',
	ERROR: 'error',
	WARNING: 'warning',
	INFO: 'info',
	HELP: 'help',
	PLACEHOLDER: 'placeholder',
};
