/* eslint react-app/import/no-webpack-loader-syntax: off */
// import LOADER from '-!svg-react-loader!@assets/images/loader-1x.svg';
import AVATAR from '@assets/images/avatar-1x.webp'
import LOADER from '@assets/images/loader-1x.webp'
import LOGO from '-!svg-react-loader!@assets/images/logo-1x.svg'

/*
// Branding - regular
import GRISPORT_REGULAR from '@assets/images/branding/regular/grisport-logo-200x200.png'
import MRMILES_REGULAR from '@assets/images/branding/regular/mrmiles-logo-200x200.png'
import REDBRICK_REGULAR from '@assets/images/branding/regular/redbrick-logo-200x200.png'
import REEBOKWORK_REGULAR from '@assets/images/branding/regular/reebok-logo-200x200.png'
import REEBOKWORK_WHITE_REGULAR from '@assets/images/branding/regular/reebok-logo-200x200.png'

// Branding - small
import GRISPORT_SMALL from '@assets/images/branding/small/grisport-logo-80x60.png'
import MRMILES_SMALL from '@assets/images/branding/small/mrmiles-logo-80x60.png'
import REDBRICK_SMALL from '@assets/images/branding/small/redbrick-logo-80x60.png'
import REEBOKWORK_SMALL from '@assets/images/branding/small/reebok-logo-80x60.png'
import REEBOKWORK_WHITE_SMALL from '@assets/images/branding/small/reebok-logo-white-80x60.png'
*/

// Branding - regular
import GRISPORT_REGULAR from '-!svg-react-loader!@assets/images/branding/regular/grisport-branding-regular-1x.svg'
import MRMILES_REGULAR from '-!svg-react-loader!@assets/images/branding/regular/mrmiles-branding-regular-1x.svg'
import REDBRICK_REGULAR from '-!svg-react-loader!@assets/images/branding/regular/redbrick-branding-regular-1x.svg'
import REEBOKWORK_REGULAR from '-!svg-react-loader!@assets/images/branding/regular/reebokworks-branding-regular-1x.svg'
import REEBOKWORK_WHITE_REGULAR from '-!svg-react-loader!@assets/images/branding/regular/reebokworks-branding-regular-white-1x.svg'

// Branding - small
import GRISPORT_SMALL from '-!svg-react-loader!@assets/images/branding/small/grisport-branding-small-1x.svg'
import MRMILES_SMALL from '-!svg-react-loader!@assets/images/branding/small/mrmiles-branding-small-1x.svg'
import MRMILES_WHITE_SMALL from '-!svg-react-loader!@assets/images/branding/small/mrmiles-branding-small-white-1x.svg'
import REDBRICK_SMALL from '-!svg-react-loader!@assets/images/branding/small/redbrick-branding-small-1x.svg'
import REDBRICK_WHITE_SMALL from '-!svg-react-loader!@assets/images/branding/small/redbrick-branding-small-white-1x.svg'
import REEBOKWORK_SMALL from '-!svg-react-loader!@assets/images/branding/small/reebokworks-branding-small-1x.svg'
import REEBOKWORK_WHITE_SMALL from '-!svg-react-loader!@assets/images/branding/small/reebokworks-branding-small-white-1x.svg'

export const VISUALS = {
	AVATAR,
	LOADER,
	LOGO,
	BRANDING: {
		REGULAR: {
			GRISPORT_REGULAR,
			MRMILES_REGULAR,
			REDBRICK_REGULAR,
			REEBOKWORK_REGULAR,
		},
		SMALL: {
			GRISPORT_SMALL: GRISPORT_SMALL,
			MRMILES_SMALL: MRMILES_WHITE_SMALL,
			REDBRICK_SMALL: REDBRICK_WHITE_SMALL,
			REEBOKWORK_SMALL: REEBOKWORK_WHITE_SMALL,
		},
		SHOE_CARDS: {
			REGULAR: {
				GRISPORT_REGULAR,
				MRMILES_REGULAR,
				REDBRICK_REGULAR,
				REEBOKWORK_REGULAR,
			},
			SMALL: {
				GRISPORT_SMALL: GRISPORT_SMALL,
				MRMILES_SMALL: MRMILES_WHITE_SMALL,
				REDBRICK_SMALL: REDBRICK_WHITE_SMALL,
				REEBOKWORK_SMALL: REEBOKWORK_WHITE_SMALL,
			},
		},
	},
}

export default {
	AVATAR,
	LOADER,
	LOGO,
	BRANDING: {
		REGULAR: {
			GRISPORT_REGULAR,
			MRMILES_REGULAR,
			REDBRICK_REGULAR,
			REEBOKWORK_REGULAR,
		},
		SMALL: {
			GRISPORT_SMALL: GRISPORT_SMALL,
			MRMILES_SMALL: MRMILES_WHITE_SMALL,
			REDBRICK_SMALL: REDBRICK_WHITE_SMALL,
			REEBOKWORK_SMALL: REEBOKWORK_WHITE_SMALL,
		},
		SHOE_CARDS: {
			REGULAR: {
				GRISPORT_REGULAR,
				MRMILES_REGULAR,
				REDBRICK_REGULAR,
				REEBOKWORK_REGULAR,
			},
			SMALL: {
				GRISPORT_SMALL: GRISPORT_SMALL,
				MRMILES_SMALL: MRMILES_WHITE_SMALL,
				REDBRICK_SMALL: REDBRICK_WHITE_SMALL,
				REEBOKWORK_SMALL: REEBOKWORK_WHITE_SMALL,
			},
		},
	},
}
