// Imports => MOBX
import { makeObservable, observable, computed, action, toJS, set } from 'mobx'

// Imports => Constants
import { KEYS, TITLES, VISUALS } from '@constants'

// Imports => Utilities
import { AcUUID, AcIsSet, AcIsUndefined } from '@utils'

const _default = {
  modal: {
    id: AcUUID(),
    visible: false,
    title: '',
    body: [],
    actions: [],
    closeable: true,
    callback: () => {},
  },
  navigation: {
    visible: false,
    expanded: [],
  },
  sub_navigation: {
    visible: false,
  },
  scroll: {
    down: false,
  },
  header: {
    id: AcUUID(),
    theme: null,
    logo: true,
    logo_visual: null,
    title: false,
    closeable: false,
    progress: false,
    transparent: true,
  },
  drawer: {
    id: AcUUID(),
    visible: false,
    actions: null,
  },
  breadcrumbs: {
    items: [],
  },
  view_more: {
    visible: false,
  },
  filters: {
    visible: false,
  },
}

let _delay = null
let _target = null

export class UiStore {
  constructor(store) {
    makeObservable(this)

    this.store = store
  }

  @observable navigation = _default.navigation

  @observable sub_navigation = _default.sub_navigation

  @observable drawer = _default.drawer

  @observable header = _default.header

  @observable breadcrumbs = _default.breadcrumbs

  @observable modal = _default.modal

  @observable scroll = _default.scroll

  @observable view_more = _default.view_more

  @observable filters = _default.filters

  @computed
  get current_view_more() {
    return this.view_more
  }

  @computed
  get current_filters() {
    return this.filters
  }

  @computed
  get current_modal() {
    return this.modal
  }

  @computed
  get current_navigation() {
    return this.navigation
  }

  @computed
  get current_sub_navigation() {
    return this.sub_navigation
  }

  @computed
  get current_drawer() {
    return this.drawer
  }

  @computed
  get current_breadcrumbs() {
    return this.breadcrumbs && this.breadcrumbs.items
  }

  @computed
  get current_scroll() {
    return this.scroll
  }

  @computed
  get current_header() {
    return this.header
  }

  @action
  confirm = async (options = {}) => {
    await this.reset(KEYS.MODAL)
    return await this.setInstance(KEYS.MODAL, {
      title: TITLES.DELETE_CONFIRM,
      tag: KEYS.CONFIRM,
      props: options,
      closeable: false,
      visible: true,
      centered: true,
      ...options,
    })
  }

  @action
  emailModal = async (options = {}) => {
    await this.reset(KEYS.MODAL)
    return await this.setInstance(KEYS.MODAL, {
      title: options.title,
      props: options,
      closeable: true,
      visible: true,
      centered: true,
      ...options,
    })
  }

  @action
  setInstance = (target, input, initial = true) => {
    return new Promise((resolve) => {
      if (!AcIsSet(target)) return
      if (AcIsUndefined(this[target])) return
      if (AcIsUndefined(input)) return

      set(this, target, input)

      resolve()
    })
  }

  @action
  setValue = (target, property, value) => {
    return new Promise((resolve, reject) => {
      if (_delay && _target === target) clearTimeout(_delay)

      if (!AcIsSet(target)) {
        reject(`[store][ui] action.setValue: 'target' is not defined`)
        return
      } else if (!AcIsSet(property)) {
        reject(`[store][ui] action.setValue: 'property' is not defined`)
        return
      } else if (AcIsUndefined(this[target])) {
        reject(`[store][ui] action.setValue: target '${target}' is not an ui element`)
        return
      } else if (AcIsUndefined(this[target][property])) {
        console.log(this[target], this[target][property])
        reject(`[store][ui] action.setValue: property '${property}' is not part of target ${target}`)
        return
      } else if (AcIsUndefined(value)) {
        reject(`[store][ui] action.setValue: value is not defined`)
        return
      }

      this[target][property] = value

      resolve()
    })
  }

  @action
  reset = (target) => {
    return new Promise((resolve, reject) => {
      if (AcIsUndefined(target)) return
      if (AcIsUndefined(this[target])) return

      this[target] = _default[target]

      resolve()
    })
  }
}

export default UiStore
