import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationNL from '@assets/translations/nl.translations.json';

i18n.use(initReactI18next).init({
	fallbackLng: 'nl',
	lng: 'nl',
	languages: ['nl'],
	debug: false,
	interpolation: {
		escapeValue: false, // not needed for react as it escapes by default
	},
	resources: {
		nl: { translation: translationNL },
	},
});

export default i18n;
