export const ICONS = {
	CLIPBOARD_PULSE_OUTLINE: 'clipboard-pulse-outline',
	CLIPBOARD_TEXT_MULTIPLE_OUTLINE: 'clipboard-text-multiple-outline',
	CLIPBOARD_ALERT_OUTLINE: 'clipboard-alert-outline',
	CLIPBOARD_TEXT_OUTLINE: 'clipboard-text-outline',
	ACCOUNT: 'account',
	ACCOUNT_BUBBLE: 'account-bubble',
	ACCOUNT_CIRCLE_OUTLINE: 'account-circle-outline',
	ACCOUNT_DETAILS: 'account-details',
	ACCOUNT_DETAILS_OUTLINE: 'account-details-outline',
	ACCOUNT_OUTLINE: 'account-outline',
	ALERT_CIRCLE: 'alert-circle',
	ALERT_CIRCLE_OUTLINE: 'alert-circle-outline',
	ALERT_OCTAGRAM: 'alert-octagram',
	ALERT_OCTAGRAM_OUTLINE: 'alert-octagram-outline',
	APARTMENT: 'apartment',
	APPS: 'apps',
	ARROW_DOWN: 'arrow-down',
	ARROW_LEFT: 'arrow-left',
	ARROW_RIGHT: 'arrow-right',
	ARROW_UP: 'arrow-up',
	BELL: 'bell',
	BELL_OUTLINE: 'bell-outline',
	CALENDAR: 'calendar',
	CALENDAR_AVAILABLE: 'calendar-available',
	CALENDAR_BUSY: 'calendar-busy',
	CALENDAR_CLOCK: 'calendar-clock',
	CALENDAR_EDIT: 'calendar-edit',
	CALENDAR_EVENT: 'calendar-event',
	CALENDAR_RANGE: 'calendar-range',
	CALENDAR_SYNC: 'calendar-sync',
	CALENDAR_SYNC_OUTLINE: 'calendar-sync-outline',
	CALENDAR_TODAY: 'calendar-today',
	CARD_ACCOUNT_DETAILS: 'card-account-details',
	CARD_ACCOUNT_DETAILS_OUTLINE: 'card-account-details-outline',
	CAST: 'cast',
	CHECK: 'check',
	CHECKBOX_MARKED_CIRCLE_OUTLINE: 'checkbox-marked-circle-outline',
	CHECK_ALL: 'check-all',
	CHEVRON_DOWN: 'chevron-down',
	CHEVRON_LEFT: 'chevron-left',
	CHEVRON_RIGHT: 'chevron-right',
	CHEVRON_UP: 'chevron-up',
	CLOCK_ALERT: 'clock-alert',
	CLOCK_ALERT_OUTLINE: 'clock-alert-outline',
	CLOCK_HISTORY: 'clock-history',
	CLOSE: 'close',
	CLOSE_CIRCLE: 'close-circle',
	CLOSE_CIRCLE_OUTLINE: 'close-circle-outline',
	CLOSE_THICK: 'close-thick',
	COG: 'cog',
	COGS: 'cogs',
	COG_OUTLINE: 'cog-outline',
	COMMENT_QUESTION_OUTLINE: 'comment-question-outline',
	CONTENT_COPY1: 'content-copy1',
	CONTENT_COPY: 'content-copy',
	CONTROL_UNIT: 'control-unit',
	CONTROL_UNIT_VARIANT: 'control-unit-variant',
	CUBE_UNFOLDED: 'cube-unfolded',
	DELETE: 'delete',
	DELETE_EMPTY_OUTLINE: 'delete-empty-outline',
	DELETE_OUTLINE: 'delete-outline',
	DELETE_VARIANT: 'delete-variant',
	DELTA: 'delta',
	DESCRIPTION: 'description',
	DESCRIPTION_OUTLINE: 'description-outline',
	DEVICE_HUB: 'device-hub',
	DOTS_GRID: 'dots-grid',
	DOTS_HORIZONTAL: 'dots-horizontal',
	DOTS_VERTICAL: 'dots-vertical',
	DOWNLOAD: 'download',
	DOWNLOAD_ALT: 'download-alt',
	EAST: 'east',
	EDIT: 'edit',
	EDIT_OUTLINE: 'edit-outline',
	EMAIL: 'email',
	EMAIL_CHECK_OUTLINE: 'email-check-outline',
	EMAIL_OUTLINE: 'email-outline',
	EMAIL_SEND_OUTLINE: 'email-send-outline',
	EQUALIZER: 'equalizer',
	EQUIPMENT: 'equipment',
	EXPANSION_CARD: 'expansion-card',
	EXPANSION_CARD_VARIANT: 'expansion-card-variant',
	FACE_AGENT: 'face-agent',
	FILE_DOWNLOAD: 'file-download',
	FILE_DOWNLOAD_OUTLINE: 'file-download-outline',
	FILE_UPLOAD: 'file-upload',
	FILE_UPLOAD_OUTLINE: 'file-upload-outline',
	FILTER: 'filter',
	FILTER_OUTLINE: 'filter-outline',
	FILTER_VARIANT: 'filter-variant',
	FOLDER_MARKER_OUTLINE: 'folder-marker-outline',
	FORWARD_10: 'forward-10',
	FORWARD_30: 'forward-30',
	FORWARD_5: 'forward-5',
	GPS: 'gps',
	GPS_DISABLED: 'gps-disabled',
	GPS_FIXED: 'gps-fixed',
	GROUPS: 'groups',
	GROUPS_OUTLINE: 'groups-outline',
	GROUP_ADD: 'group-add',
	GROUP_ADD_OUTLINE: 'group-add-outline',
	GROUP_VARIANT: 'group-variant',
	HAMMER: 'hammer',
	HAMMER_VARIANT: 'hammer-variant',
	HELP: 'help',
	HELP_CIRCLE: 'help-circle',
	HELP_CIRCLE_OUTLINE: 'help-circle-outline',
	HOME: 'home',
	HOME_CITY: 'home-city',
	HOME_CITY_OUTLINE: 'home-city-outline',
	HOME_OUTLINE: 'home-outline',
	HOME_REMOVE: 'home-remove',
	HOME_REMOVE_OUTLINE: 'home-remove-outline',
	HOME_VARIANT: 'home-variant',
	HOME_VARIANT_OUTLINE: 'home-variant-outline',
	IMAGE: 'image',
	IMAGE_ADD: 'image-add',
	IMAGE_ADD_OUTLINE: 'image-add-outline',
	IMAGE_BROKEN: 'image-broken',
	IMAGE_BROKEN_OUTLINE: 'image-broken-outline',
	IMAGE_NOT_SUPPORTED: 'image-not-supported',
	IMAGE_NOT_SUPPORTED_OUTLINE: 'image-not-supported-outline',
	IMAGE_OUTLINE: 'image-outline',
	INCANDESCENT: 'incandescent',
	INCANDESCENT_DOWN: 'incandescent-down',
	INCANDESCENT_TT: 'incandescent-tt',
	INCANDESCENT_TT_DOWN: 'incandescent-tt-down',
	INFORMATION: 'information',
	INFORMATION_OUTLINE: 'information-outline',
	LIGHTBULB_ON: 'lightbulb-on',
	LIGHTBULB_ON_OUTLINE: 'lightbulb-on-outline',
	LIGHTNING_BOLT: 'lightning-bolt',
	LIST: 'list',
	LIST_BULLETED: 'list-bulleted',
	LIST_NUMBERED: 'list-numbered',
	LOCK: 'lock',
	LOCK_OPEN: 'lock-open',
	LOCK_OPEN_OUTLINE: 'lock-open-outline',
	LOCK_OUTLINE: 'lock-outline',
	LOGOUT: 'logout',
	LOGOUT_VARIANT: 'logout-variant',
	MAGNIFY: 'magnify',
	MAP: 'map',
	MAP_MARKER: 'map-marker',
	MAP_MARKER_ALT: 'map-marker-alt',
	MAP_MARKER_EDIT: 'map-marker-edit',
	MAP_MARKER_OUTLINE: 'map-marker-outline',
	MEDIATION: 'mediation',
	MENU: 'menu',
	MENU_DOWN: 'menu-down',
	MENU_LEFT: 'menu-left',
	MENU_RIGHT: 'menu-right',
	MENU_UP: 'menu-up',
	MESSAGE_PROCESSING: 'message-processing',
	MESSAGE_PROCESSING_OUTLINE: 'message-processing-outline',
	MINUS: 'minus',
	MINUS_CIRCLE_OUTLINE: 'minus-circle-outline',
	NINJA: 'ninja',
	NORTH: 'north',
	OPEN_IN_NEW: 'open-in-new',
	PERSON_ADD: 'person-add',
	PERSON_ADD_OUTLINE: 'person-add-outline',
	PERSON_OUTLINE: 'person-outline',
	PERSON_REMOVE: 'person-remove',
	PERSON_REMOVE_OUTLINE: 'person-remove-outline',
	PHONE: 'phone',
	PHONE_OUTLINE: 'phone-outline',
	PLUS: 'plus',
	PLUS_CIRCLE_OUTLINE: 'plus-circle-outline',
	POWERPACK: 'powerpack',
	PROGRESS_ALERT: 'progress-alert',
	PROGRESS_UPLOAD: 'progress-upload',
	REDO: 'redo',
	REFRESH: 'refresh',
	SHARE: 'share',
	SORT_VARIANT_LOCK: 'sort-variant-lock',
	SOUTH: 'south',
	SUPPORT_AGENT: 'support-agent',
	SYNC: 'sync',
	SYNC_ALT: 'sync-alt',
	SYNC_DISABLED: 'sync-disabled',
	SYNC_PROBLEM: 'sync-problem',
	TEXT_BOX_OUTLINE: 'text-box-outline',
	TEXT_BOX_SEARCH_OUTLINE: 'text-box-search-outline',
	TEXT_SEARCH: 'text-search',
	THUMB_DOWN: 'thumb-down',
	THUMB_DOWN_OUTLINE: 'thumb-down-outline',
	THUMB_UP: 'thumb-up',
	THUMB_UP_OUTLINE: 'thumb-up-outline',
	TIMELINE: 'timeline',
	TIMETABLE: 'timetable',
	TOTALLY_ZEN: 'totally-zen',
	TRACK_CHANGES: 'track-changes',
	TRANSLATE: 'translate',
	TRASH_CAN: 'trash-can',
	TRASH_CAN_OUTLINE: 'trash-can-outline',
	TRENDING_DOWN: 'trending-down',
	TRENDING_UP: 'trending-up',
	TUNE: 'tune',
	UNAVAILABLE: 'unavailable',
	UNDO: 'undo',
	UPLOAD: 'upload',
	WEST: 'west',
	WIFI_ON: 'wifi-on',
	WIFI_OFF: 'wifi-off',
	WINDOW_CLOSE: 'window-close',
};

export default ICONS;
