// Imports => Constants
import { ICONS } from '@constants/icons.constants'
import { TITLES } from '@constants/titles.constants'

// Imports => Utilities
import { AcUUID } from '@utils/ac-uuid'

// Imports => Views
import { AcLoading, AcOnboarding, AcQuestion, AcResults, AcSharedResults } from '@views'

export const PATHS = {
  ONBOARDING: '/',
  QUESTION: '/flow',
  LOADING: '/een-ogenblik-geduld',
  RESULTS: '/resultaten',
  SHARE_RESULTS: '/share',
}

export const ROUTES = {
  ONBOARDING: {
    id: AcUUID(),
    name: 'Onboarding',
    label: null,
    path: PATHS.ONBOARDING,
    component: AcOnboarding,
    allowed: null,
    forbidden: false,
    exact: true,
  },
  QUESTION: {
    id: AcUUID(),
    name: 'Question',
    label: null,
    path: PATHS.QUESTION,
    component: AcQuestion,
    allowed: null,
    forbidden: false,
    exact: true,
  },
  LOADING: {
    id: AcUUID(),
    name: 'Loading',
    label: null,
    path: PATHS.LOADING,
    component: AcLoading,
    allowed: null,
    forbidden: false,
    exact: true,
  },
  RESULTS: {
    id: AcUUID(),
    name: 'Results',
    label: null,
    path: PATHS.RESULTS,
    component: AcResults,
    allowed: null,
    forbidden: false,
    exact: true,
  },

  SHARED_RESULTS: {
    id: AcUUID(),
    name: 'Shared Results',
    label: null,
    path: PATHS.SHARE_RESULTS,
    component: AcSharedResults,
    allowed: null,
    forbidden: false,
    exact: true,
  },
}

export const NAVIGATION_ROUTES = []

export const DEFAULT_ROUTE = ROUTES.ONBOARDING
export const REDIRECT_ROUTE = ROUTES.ONBOARDING
