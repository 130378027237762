export const KEYS = {
  ACTIVE: 'active',
  ANSWERED_QUESTIONS: 'answeredQuestions',
  BIT: 'bit',
  BREADCRUMBS: 'breadcrumbs',
  CLOSEABLE: 'closeable',
  CONFIRM: 'confirm',
  CONTENT: 'content',
  DRAWER: 'drawer',
  FILTERS: 'filters',
  FIRST_QUESTION: 'firstQuestion',
  FLOW: 'flow',
  FLOW_ID: 'flowId',
  HEADER: 'header',
  IMAGE: 'image',
  INDEX: 'index',
  ITEMS: 'items',
  LOGO: 'logo',
  MODAL: 'modal',
  NAVIGATION: 'navigation',
  NEXT: 'next',
  PAGE: 'page',
  PREVIOUS: 'previous',
  PRODUCTS: 'products',
  PROGRESS: 'progress',
  QUESTION: 'question',
  RESULTS: 'results',
  SCROLLER: 'ac-scroller',
  SCROLL: 'scroll',
  SHARE_HASH: 'shareHash',
  SUPPORT_EMAIL_ADDRESS: 'info@allshoes.nl',
  STATE: 'state',
  SQUEEZLY_ID: 'squeezelyId',
  SQUEEZLY_QUERY_PARAM: 'sqzl',
  TENANT: 'tenant',
  TENANT_SLUG: 'tenant_slug',
  TOASTER: 'toaster',
  VISIBLE: 'visible',
  VIEW_MORE: 'view_more',
}

export default KEYS
