export const AcGTMQuestionEvent = ({ questionID, question, answer }) => {
  const questionEvent = {
    event: 'question',
    id: questionID,
    vraag: question,
    antwoord: answer,
  };

  window && window.dataLayer && window.dataLayer.push(questionEvent);
};

export const AcGTMQuestionResults = ({
  pageID,
  resultTop,
  resultPopulair,
  resultPremium,
  resultsOther,
  questions
}) => {
  const questionResultsEvent = {
    event: 'results',
    id: pageID,
    'resultaat top': resultTop,
    'resultaat populair': resultPopulair,
    'resultaat premium': resultPremium,
    'resultaten overig': resultsOther,
    'vragen en antwoorden': questions,
  };

  window && window.dataLayer && window.dataLayer.push(questionResultsEvent);
};

export const AcGTMClickOut = ({ shoeName, shoePosition, shoeURL }) => {
  const clickOutResults = {
    event: 'cta',
    schoen: shoeName,
    index: shoePosition || 'top',
    schoenURL: shoeURL,
  };
  window && window.dataLayer && window.dataLayer.push(clickOutResults);
};

export const AcGTMShareData = ({shareType = 'copy paste link', questions, results}) => {
  const shareData = {
    event: 'share',
    'vragen en antwoorden': questions,
    ...{
     shareType,
     results
    }
  }

  window && window.dataLayer && window.dataLayer.push(shareData)
}
