const API = '/api'
const APP = '/app'
const FLOW = '/flow'
const GETFLOW = '/flow'
const MAIL = '/mail'
const NEXT = '/next'
const PREVIOUS = '/previous'
const QUESTIONS = '/questions'
const SHARE = '/share'
const TENANT = '/tenant'

// V1
export const ENDPOINTS = {
  FLOW: {
    INITIATE_FLOW: `${API}${FLOW}`, // GET
    INITIATE: `${API}${QUESTIONS}${NEXT}`, // POST
    MAIL: `${API}${FLOW}${MAIL}`, // POST
    NEXT_QUESTION: `${API}${QUESTIONS}${NEXT}`, // POST
    PREVIOUS_QUESTION: `${API}${QUESTIONS}${NEXT}`, // POST
    SHARE_RESULTS: `${API}${SHARE}`, // POST
    SHARED_FLOW: `${API}${SHARE}`, // GET
  },
  TENANT: {
    INITIATE: `${API}${TENANT}`,
  },
}

export default ENDPOINTS
