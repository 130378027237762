import { useRef, useCallback } from 'react'

export const useRefWithCallback = (onMount, onUnmount = null) => {
  const nodeRef = useRef(null)

  const setRef = useCallback(
    (node) => {
      if (nodeRef.current && onUnmount) {
        onUnmount(nodeRef.current)
      }
      nodeRef.current = node
      if (nodeRef.current) {
        setTimeout(() => {
          onMount(nodeRef.current)
        }, 0)
      }
    },
    [onMount, onUnmount],
  )

  return setRef
}
