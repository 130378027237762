import loadable from '@loadable/component'

// Imports => Views: Home
const AcLoading = loadable(() => import('@views/ac-loading/ac-loading'))
const AcOnboarding = loadable(() => import('@views/ac-onboarding/ac-onboarding'))
const AcQuestion = loadable(() => import('@views/ac-question/ac-question'))
const AcResults = loadable(() => import('@views/ac-results/ac-results'))
const AcSharedResults = loadable(() => import('@views/ac-shared-results/ac-shared-results'))

export { AcLoading, AcOnboarding, AcQuestion, AcResults, AcSharedResults }
